import React from "react";
import { Button, Grid, ListItem, Typography } from "@mui/material";
import storeIcon from "../../Image/storeIcon.png";
import { useSelector } from "react-redux";
import { formatAmount } from "../../Utils/common";
import { capitalizeFirstLowercaseRest } from "../../Utils/common";
import { uiTexts } from "../../Utils/uiTexts";

// ===================================================|| PAYMENT LIST DETAILS ||=================================================== //

export const PaymentListDetails = ({
  selectedPayment,
  handlePayment,
  filteredPayments,
}) => {
  const {
    separators: { hyphenText },
  } = uiTexts;

  const { customerList } = useSelector((state) => state.data_menu);

  const getCustomerArea = (id) => {
    const customer = customerList?.find((data) => data.partyId === id);
    return customer ? customer.areaName : "";
  };
  return (
    <>
      {filteredPayments?.map((payment, index) => {
        const customerArea = getCustomerArea(payment.partyId);

        return (
          payment.totalPendingAmount !== 0 && (
            <Button
              className={`orders-payment-content--button ${
                selectedPayment &&
                payment.partyId === selectedPayment.partyId &&
                "orders-payment-content--button--selected"
              }`}
              key={index + 1}
              onClick={() => {
                handlePayment(payment);
              }}
            >
              <ListItem>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography className="customer-details-list-text">
                      <img
                        src={storeIcon}
                        className="storeIcon-style"
                        alt="logo"
                      />
                      {payment.partyName}
                      {customerArea &&
                        ` ${hyphenText} ${capitalizeFirstLowercaseRest(
                          customerArea,
                        )}`}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className="customer-details-list-text">
                      {formatAmount(payment.totalPendingAmount)}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </Button>
          )
        );
      })}
    </>
  );
};
