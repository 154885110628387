import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// ==============================================|| CUSTOM SELECT ||============================================= //

export const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  onClear,
  isdisabled,
}) => {
  return (
    <FormControl variant="outlined" className="select-comp-width">
      <InputLabel>{label}</InputLabel>

      <Select
        value={value}
        onChange={onChange}
        label={label}
        disabled={isdisabled}
        className="select-comp-height"
        endAdornment={
          value ? (
            <IconButton onClick={onClear} edge="end" sx={{ mr: 0 }}>
              <CloseIcon />
            </IconButton>
          ) : null
        }
      >
        {options?.map((option, index) => (
          <MenuItem key={option.id} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
