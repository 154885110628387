import React from "react";
import { Paper, Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import Chart from "react-apexcharts";

// ==============================================|| DISPATCH DETAILS LINE CHART ||============================================= //

export const DispatchChartDetails = ({
  dispatchedDetails,
  selectedCategory,
}) => {
  const {
    chartLabels: {
      dispatchDetailsText,
      lineText,
      smoothText,
      delayText,
      daysText,
      itemTypeText,
    },
    labels: { commodityText },
    alertLabels: { noDataText },
  } = uiTexts;

  const chartLabels = selectedCategory
    ? dispatchedDetails?.map((item) => item.itemType || "").filter(Boolean)
    : dispatchedDetails?.map((item) => item.categoryName || "").filter(Boolean);

  const chartData = {
    labels: chartLabels,
    series: [
      {
        name: delayText,
        data: dispatchedDetails?.map((item) => item.delayDays),
      },
    ],
  };

  const options = {
    chart: {
      type: lineText,
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    stroke: {
      curve: smoothText,
    },
    markers: {
      size: 4,
      colors: ["#FF0000"],
      strokeColors: "#000",
    },
    xaxis: {
      categories: chartData?.labels,
      title: {
        text: selectedCategory ? commodityText : itemTypeText,
        style: {
          color: "var(--analysis-chart-label-color)",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: {
        text: `${delayText} ${daysText}`,
        style: {
          color: "var(--analysis-chart-label-color)",
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} ${daysText}`,
      },
    },
    colors: ["#000"],
    grid: {
      show: false,
    },
  };

  return (
    <Paper className="chart-summary-background">
      <Typography className="chart-header-text">
        {dispatchDetailsText}
      </Typography>

      {chartData?.labels?.length === 0 ? (
        <div className="no-data-text">
          <Typography>{noDataText}</Typography>
        </div>
      ) : (
        <Chart
          options={options}
          series={chartData?.series}
          type={lineText}
          width="100%"
          height="350"
        />
      )}
    </Paper>
  );
};
