import React from "react";
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// ==========================================|| KPI TABLE HEADER ||=========================================== //

export const KpiDetailsTableHeader = ({ tableHeaderText }) => {
  const {
    labels: { averageText },
  } = uiTexts;

  return (
    <TableHead>
      <TableRow>
        <TableCell className="remove-border-bottom kpi-table-cell-padding">
          <Typography className="bold-text">{tableHeaderText}</Typography>
        </TableCell>

        <TableCell className="remove-border-bottom kpi-table-cell-padding">
          <Typography className="bold-text">{averageText}</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
