import React from "react";
import { Stack } from "@mui/material";
import { SalesSummary } from "./SalesSummary";
import { CommodityWiseOrdersChart } from "./CommodityWiseOrdersChart";
import { BrandWiseOrdersChart } from "./BrandWiseOrdersChart";
import { useSelector } from "react-redux";

// ==============================================|| ANALYSIS HEADER ||============================================= //

export const AnalysisHeader = ({ selectedCategory }) => {
  const { analysisDetails, analysisStatus } = useSelector(
    (state) => state.kpi_menu,
  );

  return (
    <Stack direction="row" className="analysis-header-stack">
      <SalesSummary />

      {selectedCategory === "" ? (
        <CommodityWiseOrdersChart
          commodityOrderDetails={analysisDetails?.commodityOrderDetails}
        />
      ) : (
        analysisStatus && (
          <BrandWiseOrdersChart
            brandOrderDetails={analysisDetails?.commodityOrderDetails}
          />
        )
      )}
    </Stack>
  );
};
