import React from "react";
import { Grid, Autocomplete, TextField, Typography } from "@mui/material";
import { uiTexts } from "../../../../Utils/uiTexts";
import { useSelector } from "react-redux";
import { CustomerResponsibility } from "../../Bill Details/CustomerResponsibility";

// ===================================================|| BILL FORM ||=================================================== //

export const TransportInputFields = ({
  customerResponsibility,
  selectedTransport,
  handleFieldChange,
  isEditBillModal,
}) => {
  const {
    labels: { transportNameText, transportText },
    placeHolderTexts: { chooseTransportText },
    buttonText: { noText },
  } = uiTexts;

  const { transportData } = useSelector((state) => state.data_menu);

  return (
    <>
      <Grid
        container
        spacing={2}
        className="order-details-modal-table bill-modal-transport-table"
      >
        <CustomerResponsibility
          customerResponsibility={customerResponsibility}
          handleFieldChange={handleFieldChange}
          isEditBillModal={isEditBillModal}
        />
      </Grid>

      {customerResponsibility === noText && (
        <Grid
          container
          spacing={2}
          className="order-details-modal-table bill-modal-transport-table"
        >
          {/* Transport Name */}
          <Grid item xs="2.5">
            <Typography className="bill-modal-text">
              {transportNameText}
            </Typography>
          </Grid>

          <Autocomplete
            disablePortal
            options={transportData}
            getOptionLabel={(option) => option.transportName}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.transportName}
              </li>
            )}
            value={selectedTransport}
            onChange={(event, value) => handleFieldChange(transportText, value)}
            sx={{ width: 260 }}
            renderInput={(params) => (
              <TextField {...params} label={chooseTransportText} />
            )}
            xs={3.5}
          />
        </Grid>
      )}
    </>
  );
};
