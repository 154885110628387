import React from "react";
import { Button, Grid, ListItem, Typography, Stack } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import BillIcon from "../../Image/billIcon.png";
import cashIcon from "../../Image/cashIcon.png";
import { useSelector } from "react-redux";
import { formatDate, formatAmount } from "../../Utils/common";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

// ===================================================|| BILL LIST ||=================================================== //

export const BillList = ({ selectedPayment, handleBillDetails }) => {
  const { selectedBillDetails } = useSelector((state) => state.data_menu);
  const {
    separators: { leftParenthesisText, rightParenthesisText, hyphenText },
  } = uiTexts;

  const IconText = ({ iconSrc, text, style }) => (
    <Stack direction="row" style={style}>
      <img src={iconSrc} className="payment-icon-style" alt="Icon" />
      <Typography className="bill-details-list-style">{text}</Typography>
    </Stack>
  );

  return (
    <>
      {selectedPayment?.billNos.map(
        (bill, index) =>
          bill.totalPendingAmount !== 0 && (
            <Button
              className={`orders-payment-content--button ${
                selectedBillDetails &&
                bill.billNo === selectedBillDetails.billNo &&
                "orders-payment-content--button--selected"
              }`}
              key={index + 1}
              onClick={() => {
                handleBillDetails(bill);
              }}
            >
              <ListItem>
                <Grid container justifyContent="space-between">
                  <Grid item xs={6}>
                    <IconText
                      iconSrc={BillIcon}
                      text={`${bill.billNo} ${leftParenthesisText} ${formatDate(
                        bill.billedDate,
                      )} ${rightParenthesisText} ${hyphenText} ${
                        bill.firmName
                      }`}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <IconText
                      iconSrc={cashIcon}
                      text={
                        <>
                          <CurrencyRupeeIcon className="payment-currency-icon custom-currency-icon" />
                          {formatAmount(bill.totalPendingAmount)}
                        </>
                      }
                      style={{ justifyContent: "flex-end" }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </Button>
          ),
      )}
    </>
  );
};
