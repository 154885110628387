import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";
import { Loader } from "../../Loader/Loader";
import { SearchField } from "../../CommonComp/SearchField";
import { NavigateBackIcon } from "../../CommonComp/NavigateBackIcon";
import { AccordionItems } from "./AccordionItems";
import "../PendingBags.css";

// ===================================================|| ACCORDION LIST ||=================================================== //

export const AccordionList = ({
  isHomePage,
  selectedCategory,
  categoryList,
}) => {
  const { itemWiseOrderedData } = useSelector((state) => state.data_menu);

  const {
    warnings: { noPendingBagsText },
    placeHolderTexts: { searchItemText },
    headerLabels: { pendingBagsText },
  } = uiTexts;

  const [searchList, setSearchList] = useState("");
  const [itemListLoader, setItemListLoader] = useState(false);
  const [itemsWithOrderCount, setItemsWithOrderCount] = useState([]);

  useEffect(() => {
    //Store items which have order count
    const filteredItems = itemWiseOrderedData?.filter(
      (item) => item.orderCount > 0,
    );
    if (isHomePage && selectedCategory) {
      const selectedCategoryName = categoryList?.find(
        (category) => category.id === selectedCategory,
      )?.categoryName;

      const filteredByCategory = filteredItems?.filter((item) =>
        item.itemName
          .toLowerCase()
          .includes(selectedCategoryName.toLowerCase()),
      );
      setItemsWithOrderCount(filteredByCategory);
    } else {
      setItemsWithOrderCount(filteredItems);
    }
  }, [itemWiseOrderedData, categoryList, selectedCategory, isHomePage]);

  return (
    <>
      {itemListLoader && <Loader />}

      {/* Display accordion only if only have order */}
      {itemsWithOrderCount?.length === 0 ? (
        <div
          className={
            isHomePage
              ? "no-bags-error-wrapper"
              : "no-bags-error-wrapper no-bags-error-wrapper-height"
          }
        >
          <Typography
            className={
              isHomePage
                ? "no-bags-error-text home-page-no-pending-bags-error-text-margin-top"
                : "no-bags-error-margin-top no-bags-error-text"
            }
          >
            {noPendingBagsText}
          </Typography>
        </div>
      ) : (
        <>
          {!isHomePage && (
            <>
              <div className="pending-bags-page-style item-list-backIcon-container">
                <NavigateBackIcon iconLabel={pendingBagsText} />
              </div>

              <div className="pending-bags-page-style item-search-container">
                <SearchField
                  searchList={searchList}
                  setSearchList={setSearchList}
                  searchLabel={searchItemText}
                />
              </div>
            </>
          )}

          <AccordionItems
            isHomePage={isHomePage}
            searchList={searchList}
            setItemListLoader={setItemListLoader}
            itemsWithOrderCount={itemsWithOrderCount}
            setItemsWithOrderCount={setItemsWithOrderCount}
            itemWiseOrderedData={itemWiseOrderedData}
          />
        </>
      )}
    </>
  );
};
