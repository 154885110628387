import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
import { KpiHeaderDetails } from "../KpiHeaderDetails";
import { KpiDetailsTableHeader } from "./KpiDetailsTableHeader";

// ==========================================|| KPI TABLE DETAILS ||=========================================== //

export const KpiDetailsTableDetails = ({
  data,
  headerText,
  column1Text,
  column1Key,
  column2Key,
}) => {
  const KpiDetailsTableCell = ({ label }) => {
    return (
      <TableCell className="remove-border-bottom kpi-table-cell-padding">
        {label}
      </TableCell>
    );
  };

  return (
    <>
      <KpiHeaderDetails headerText={headerText} />

      <TableContainer className="kpi-details-table-style">
        <Table stickyHeader>
          <KpiDetailsTableHeader tableHeaderText={column1Text} />

          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index}>
                <KpiDetailsTableCell label={row[column1Key]} />
                <KpiDetailsTableCell label={row[column2Key]} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
