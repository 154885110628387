import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../Utils/uiTexts";
import { ReportActionButton } from "./ReportActionsButton";
import { ReportFields } from "./ReportFields";
import "../../Report.css";

// ===================================================|| REPORT FILTERS ||=================================================== //

export const AdditionalFilters = ({
  selectedFirm,
  selectedCategory,
  setSelectedFirm,
  setSelctedCategory,
  handleFieldChange,
  isClearDisabled,
  isDownloadDisabled,
  handleClear,
  handleDownload,
}) => {
  const { firmNames, categoryList } = useSelector((state) => state.data_menu);
  const {
    labels: { categoryText, firmNameText },
    placeHolderTexts: { chooseFirmText, chooseCategoryText },
  } = uiTexts;

  return (
    <Grid container item xs={12} sx={{ ml: 2 }}>
      {/* Firm Name */}
      <ReportFields
        label={firmNameText}
        placeHolder={chooseFirmText}
        select
        value={selectedFirm}
        onChange={(e) => handleFieldChange(chooseFirmText, e.target.value)}
        options={firmNames?.map((firm) => ({
          label: firm.firmName,
          value: firm.id,
        }))}
        isDeleteShow={true}
        onDeleteClick={() => setSelectedFirm("")}
      />

      {/* Category name */}
      <ReportFields
        label={categoryText}
        placeHolder={chooseCategoryText}
        select
        value={selectedCategory}
        onChange={(e) => handleFieldChange(chooseCategoryText, e.target.value)}
        options={categoryList?.map((report) => ({
          label: report.categoryName,
          value: report.id,
        }))}
        isDeleteShow={true}
        onDeleteClick={() => setSelctedCategory("")}
      />

      <ReportActionButton
        isClearDisabled={isClearDisabled}
        isDownloadDisabled={isDownloadDisabled}
        handleClear={handleClear}
        handleDownload={handleDownload}
      />
    </Grid>
  );
};
