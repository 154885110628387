import React from "react";
import { useSelector } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";
import { KpiDetailsTableDetails } from "./KpiTable/KpiDetailsTableDetails";

// ==========================================|| KPI LHS ||=========================================== //

export const KpiLhs = ({ selectedKpi }) => {
  const {
    orderToBillDelayDetails,
    billToDispatchDelayDetails,
    billToPaymentDelayDetails,
  } = useSelector((state) => state.kpi_menu);

  const {
    kpiCardsLabels: {
      orderToBillAverageText,
      orderToBillText,
      bagWiseDispatchAverage,
      billToPaymentAverageText,
      billToPaymentText,
      billToDispatchText,
    },
    separators: { hyphenText },
    labels: { customerText, bagText },
    keyLabels: { categoryNameKey },
  } = uiTexts;

  const billToDispatchBagWise = billToDispatchDelayDetails?.bagWise
    ? Object.entries(billToDispatchDelayDetails?.bagWise).map(
        ([key, value]) => ({
          Bag: key,
          billToDispatch: value !== null ? value : hyphenText,
        }),
      )
    : [];

  return (
    <>
      {selectedKpi?.index === 0 && (
        <KpiDetailsTableDetails
          data={orderToBillDelayDetails?.categoryWise}
          headerText={orderToBillAverageText}
          column1Text={customerText}
          column1Key={categoryNameKey}
          column2Key={orderToBillText}
        />
      )}

      {selectedKpi?.index === 1 && (
        <KpiDetailsTableDetails
          data={billToDispatchBagWise}
          headerText={bagWiseDispatchAverage}
          column1Text={bagText}
          column1Key={bagText}
          column2Key={billToDispatchText}
        />
      )}

      {selectedKpi?.index === 2 && (
        <KpiDetailsTableDetails
          data={billToPaymentDelayDetails?.categoryWise}
          headerText={billToPaymentAverageText}
          column1Text={customerText}
          column1Key={categoryNameKey}
          column2Key={billToPaymentText}
        />
      )}
    </>
  );
};
