import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { KpiDateField } from "./KpiDateField";
import { KpiCards } from "./KpiCards";
import useRetrieveFunctions from "../../CustomHooks/useRetrieveFunctions";
import { uiTexts } from "../../Utils/uiTexts";

// ==========================================|| KPI HEADER ||=========================================== //

export const KpiHeader = ({
  selectedKpi,
  setSelectedKpi,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const {
    placeHolderTexts: { dateFormat },
  } = uiTexts;

  const {
    retrieveOverAllDelayDetails,
    retrieveOrderToBillDelay,
    retrieveBillToDispatchDelay,
    retrieveBillToPaymentDelay,
  } = useRetrieveFunctions();

  const [dateError, setDateError] = useState("");

  useEffect(() => {
    if (
      (dateError === "" && startDate && endDate) ||
      (!startDate && !endDate)
    ) {
      const formattedStartDate = startDate?.format(dateFormat);
      const formattedEndDate = endDate?.format(dateFormat);

      retrieveOverAllDelayDetails(formattedStartDate, formattedEndDate);

      // Check if selectedKpi.index is not null and retrieve corresponding delay details
      if (selectedKpi.index !== null) {
        const delayFunctions = [
          retrieveOrderToBillDelay,
          retrieveBillToDispatchDelay,
          retrieveBillToPaymentDelay,
        ];

        const kpiIndex = selectedKpi.index;
        if (delayFunctions[kpiIndex]) {
          delayFunctions[kpiIndex](formattedStartDate, formattedEndDate);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <Grid
      container
      alignItems="center"
      className="kpi-flex-row kpi-flex-wrap kpi-header-margin"
    >
      <KpiDateField
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        dateError={dateError}
        setDateError={setDateError}
      />

      <KpiCards
        selectedKpi={selectedKpi}
        setSelectedKpi={setSelectedKpi}
        startDate={startDate}
        endDate={endDate}
      />
    </Grid>
  );
};
