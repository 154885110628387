import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";
import { PartySelect } from "./PartySelect";
import { CustomSelect } from "./CustomSelect";

// ============================================|| ANALYSIS FILTERS ||============================================ //

export const AnalysisFilters = ({
  selectedCategory,
  setSelectedCategory,
  selectedParty,
  setSelectedParty,
  selectedRange,
  setSelectedRange,
}) => {
  const {
    placeHolderTexts: { chooseCategoryText, chooseRangeText },
  } = uiTexts;

  const { categoryList } = useSelector((state) => state.data_menu);
  const { paymentRange } = useSelector((state) => state.kpi_menu);

  const isDisableFilter = selectedCategory === "";

  const handleClearCategory = () => {
    setSelectedCategory("");
    setSelectedParty({ id: "", name: "" });
    setSelectedRange("");
  };

  const handleClearRange = () => setSelectedRange("");

  return (
    <Grid container spacing={2} className="filters-grid">
      <Grid item>
        <CustomSelect
          label={chooseCategoryText}
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          onClear={handleClearCategory}
          options={categoryList?.map((category) => ({
            id: category.id,
            value: category.categoryName,
          }))}
        />
      </Grid>

      <Grid item>
        <PartySelect
          selectedParty={selectedParty}
          setSelectedParty={setSelectedParty}
          selectedCategory={selectedCategory}
          isDisableFilter={isDisableFilter}
        />
      </Grid>

      <Grid item>
        <CustomSelect
          label={chooseRangeText}
          value={selectedRange || ""}
          onChange={(e) => setSelectedRange(e.target.value)}
          onClear={handleClearRange}
          options={paymentRange?.map((range) => ({
            id: range,
            value: range,
          }))}
          isdisabled={isDisableFilter}
        />
      </Grid>
    </Grid>
  );
};
