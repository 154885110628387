import React from "react";
import { Grid } from "@mui/material";
import { CommonFilters } from "./CommonFilters";
import { AdditionalFilters } from "./AdditionalFilters";
import "../../Report.css";

// ===================================================|| REPORT FILTERS ||=================================================== //

export const ReportFilters = ({
  reportType,
  setReportType,
  reportDate,
  selectedFirm,
  selectedCategory,
  handleFieldChange,
  isClearDisabled,
  isDownloadDisabled,
  handleClear,
  handleDownload,
  setSelectedFirm,
  setSelctedCategory,
}) => {
  return (
    <Grid container spacing={2}>
      <CommonFilters
        reportType={reportType}
        setReportType={setReportType}
        reportDate={reportDate}
        handleFieldChange={handleFieldChange}
        isClearDisabled={isClearDisabled}
        isDownloadDisabled={isDownloadDisabled}
        handleClear={handleClear}
        handleDownload={handleDownload}
      />

      {reportType === 2 && (
        <AdditionalFilters
          selectedFirm={selectedFirm}
          selectedCategory={selectedCategory}
          setSelectedFirm={setSelectedFirm}
          setSelctedCategory={setSelctedCategory}
          handleFieldChange={handleFieldChange}
          isClearDisabled={isClearDisabled}
          isDownloadDisabled={isDownloadDisabled}
          handleClear={handleClear}
          handleDownload={handleDownload}
        />
      )}
    </Grid>
  );
};
