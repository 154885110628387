import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import PendingBags from "../Pending Bags/PendingBags";
import { CategoryButtons } from "./CategoryButtons";
import { Loader } from "../Loader/Loader";

// ===================================================|| Home Page ||=================================================== //

export const HomePage = () => {
  const { categoryList } = useSelector((state) => state.data_menu);
  const { categoryStatusCode } = useSelector((state) => state.status_menu);

  const { retrieveCategories } = useRetrieveFunctions();

  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    retrieveCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      {!categoryStatusCode ? (
        <Loader />
      ) : (
        <>
          <CategoryButtons
            categoryList={categoryList}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />

          <PendingBags
            isHomePage={true}
            selectedCategory={selectedCategory}
            categoryList={categoryList}
          />
        </>
      )}

      <Footer />
    </>
  );
};
