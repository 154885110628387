import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import "../PendingBags.css";
import { AccordionItemDetail } from "./AccordionItemDetail";

// ===================================================|| ACCORDION ITEMS ||=================================================== //

export const AccordionItems = ({
  isHomePage,
  searchList,
  setItemListLoader,
  itemsWithOrderCount,
  setItemsWithOrderCount,
  itemWiseOrderedData,
}) => {
  const {
    warnings: { ItemNotFoundText },
  } = uiTexts;

  const [openAccordions, setOpenAccordions] = useState([]);

  //Filter items based on search
  const filteredItems = itemsWithOrderCount?.filter((item) =>
    item.itemName.toLowerCase().includes(searchList.toLowerCase()),
  );

  const isEmptyData =
    itemWiseOrderedData.length !== 0 &&
    (filteredItems?.length === 0 || filteredItems?.length === undefined);

  useEffect(() => {
    //Close all accordion if searchlist is changed
    setOpenAccordions([]);
  }, [searchList]);

  return (
    <div
      className={
        isHomePage
          ? "home-page-pending-bag-list item-list-container"
          : "item-list-container"
      }
    >
      {filteredItems
        ?.sort((a, b) => b.orderCount - a.orderCount)
        ?.map(
          (items, index) =>
            items.orderCount !== 0 && (
              <AccordionItemDetail
                setItemListLoader={setItemListLoader}
                index={index}
                items={items}
                setItemsWithOrderCount={setItemsWithOrderCount}
                openAccordions={openAccordions}
                setOpenAccordions={setOpenAccordions}
              />
            ),
        )}

      {isEmptyData && (
        <Typography className="error-record-not-found">
          {ItemNotFoundText}
        </Typography>
      )}
    </div>
  );
};
