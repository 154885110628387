import React from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { uiTexts } from "../../Utils/uiTexts";
import dayjs from "dayjs";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

// ==========================================|| KPI DATE FIELD ||=========================================== //

export const KpiDateField = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateError,
  setDateError,
}) => {
  const {
    placeHolderTexts: { dateInputFormat },
    labels: { startDateText, endDateText },
    warnings: {
      startDateMustBeEarlierThanEndDateText,
      endDateMustBeLaterThanStartDateText,
      startDateAndEndDateCannotBeSameText,
    },
  } = uiTexts;

  const startDateError =
    dateError &&
    (dateError === startDateMustBeEarlierThanEndDateText ||
      dateError === startDateAndEndDateCannotBeSameText);
  const endDateError =
    dateError &&
    (dateError === endDateMustBeLaterThanStartDateText ||
      dateError === startDateAndEndDateCannotBeSameText);

  const handleStartDateChange = (newValue) => {
    if (endDate && newValue) {
      if (dayjs(newValue).isAfter(endDate)) {
        setDateError(startDateMustBeEarlierThanEndDateText);
      } else if (dayjs(newValue).isSame(endDate)) {
        setDateError(startDateAndEndDateCannotBeSameText);
      } else {
        setDateError("");
      }
    } else {
      setDateError("");
    }
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    if (startDate && newValue) {
      if (dayjs(newValue).isBefore(startDate)) {
        setDateError(endDateMustBeLaterThanStartDateText);
      } else if (dayjs(newValue).isSame(startDate)) {
        setDateError(startDateAndEndDateCannotBeSameText);
      } else {
        setDateError("");
      }
    } else {
      setDateError("");
    }
    setEndDate(newValue);
  };

  const CustomDatePicker = ({ label, value, onChange, fieldStyle }) => {
    const handleClearDate = () => {
      onChange(null);
    };

    return (
      <div className=" kpi-flex-row date-fields-div">
        <FormControl className="date-field-style">
          <FormLabel className="date-picker-labels">{label}</FormLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={value}
              format={dateInputFormat}
              className={fieldStyle}
              slotProps={{
                textField: {
                  className: fieldStyle,
                  sx: {
                    "& .MuiInputBase-root": {
                      height: "40px",
                    },
                  },
                },
              }}
              onChange={onChange}
            />
          </LocalizationProvider>
        </FormControl>

        {value && (
          <IconButton onClick={handleClearDate} className="date-remove-icon">
            <RemoveCircleOutlineIcon className="search-close-icon" />
          </IconButton>
        )}
      </div>
    );
  };

  return (
    <Grid item xs={2.5} className="kpi-flex-column date-field-overall-grid">
      <CustomDatePicker
        label={startDateText}
        value={startDate}
        onChange={handleStartDateChange}
        fieldStyle={`date-field-style errordate ${
          startDateError ? "error" : ""
        }`}
      />

      <CustomDatePicker
        label={endDateText}
        value={endDate}
        onChange={handleEndDateChange}
        fieldStyle={`date-field-style errordate ${endDateError ? "error" : ""}`}
      />

      <Box className="error-box-style">
        {dateError && (
          <Typography className="date-error-text">{dateError}</Typography>
        )}
      </Box>
    </Grid>
  );
};
