import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";
import { ItemListAccordionSummary } from "./ItemListAccordionSummary/ItemListAccordionSummary";
import { getItemWiseOrderById } from "../../Services/OrderService";
import { ItemListAccordionHeader } from "./ItemAccordionHeader";
import "../PendingBags.css";

// ===================================================|| ACCORDION ITEM DETAIL ||=================================================== //

export const AccordionItemDetail = ({
  setItemListLoader,
  index,
  items,
  setItemsWithOrderCount,
  openAccordions,
  setOpenAccordions,
}) => {
  const { orderItemTransactionsData } = useSelector((state) => state.data_menu);

  const {
    warnings: { noDetailsAvailableForThisItemText },
  } = uiTexts;

  const [selectedItemDetails, setSelectedItemDetails] = useState([]);

  const handleAccordionExpand = async (id, index, isExpanded) => {
    if (isExpanded) {
      setItemListLoader(true);
      try {
        const { data: itemDetails } = await getItemWiseOrderById(id);
        const orderItemTransactionIds = itemDetails?.map(
          (item) => item.orderItemTransactionId,
        );

        const itemOrderDetails = orderItemTransactionsData?.filter((filRes) =>
          orderItemTransactionIds.includes(filRes.id),
        );

        setSelectedItemDetails((prev) => ({
          ...prev,
          [index]: { itemDetails, itemOrderDetails },
        }));
      } catch (error) {
        console.error("Error fetching item details:", error);
      } finally {
        setItemListLoader(false);
      }
    }

    setOpenAccordions((prevOpenAccordions) =>
      isExpanded
        ? [...prevOpenAccordions, index]
        : prevOpenAccordions.filter(
            (accordionIndex) => accordionIndex !== index,
          ),
    );
  };

  return (
    <Accordion
      key={index}
      className="item-list-accordion"
      expanded={openAccordions.includes(index)}
      onChange={(event, isExpanded) =>
        handleAccordionExpand(items.itemId, index, isExpanded)
      }
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index + 1}-content`}
        id={`panel${index + 1}-header`}
      >
        <ItemListAccordionHeader items={items} />
      </AccordionSummary>

      <AccordionDetails className="item-accordion-container">
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          {!selectedItemDetails[index] ||
          !selectedItemDetails[index].itemOrderDetails ||
          !Array.isArray(selectedItemDetails[index].itemOrderDetails) ||
          selectedItemDetails[index].itemOrderDetails.length === 0 ? (
            <Typography className="no-details-warning-text">
              {noDetailsAvailableForThisItemText}
            </Typography>
          ) : (
            <ItemListAccordionSummary
              itemDetailsByIndex={selectedItemDetails[index].itemDetails}
              itemOrderDetailsByIndex={
                selectedItemDetails[index].itemOrderDetails
              }
            />
          )}
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};
