import React from "react";
import { Grid } from "@mui/material";
import { EditBillsLhsListHeader } from "./EditBillsLhsListHeader";
import { EditBillsLhsListItem } from "./EditBillsLhsListItem";
import { EditBillsRhsContent } from "../EditBillsRhsContent/EditBillsRhsContent";
import "../../CustomersTab/CustomerPage.css";
import "../../EditBills/editbills.css";

// ============================================|| EDIT BILL LHS CONTENT ||============================================ //

export const EditBillLhsContent = ({
  searchList,
  setSelectedCustomer,
  selectedCustomer,
  filteredCustomersData,
}) => {
  return (
    <Grid container className="edit-bill-page-area payment-list">
      <Grid item xs={6} className="payment-grid">
        <EditBillsLhsListHeader filteredCustomersData={filteredCustomersData} />

        <EditBillsLhsListItem
          searchList={searchList}
          setSelectedCustomer={setSelectedCustomer}
          selectedCustomer={selectedCustomer}
          filteredCustomersData={filteredCustomersData}
        />
      </Grid>

      {selectedCustomer && (
        <EditBillsRhsContent
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
    </Grid>
  );
};
