import React, { useState, useEffect } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../Loader/Loader";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { AnalysisPage } from "./AnalysisPage";
import { AnalysisStatus, SalesSummaryStatus } from "../Store/Reducers/kpi_menu";
import { CircularLoader } from "../Loader/CircularLoader";
import "./Analysis.css";

// ==============================================|| ANALYSIS ||============================================= //

function Analysis() {
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedParty, setSelectedParty] = useState({ id: "", name: "" });
  const [selectedRange, setSelectedRange] = useState("");

  const { customerStatusCode, categoryStatusCode } = useSelector(
    (state) => state.status_menu,
  );
  const {
    salesSummaryDetails = [],
    salesSummaryStatus,
    analysisStatus,
    analysisDetails = [],
  } = useSelector((state) => state.kpi_menu);

  const isAnalysisLoader =
    !customerStatusCode ||
    !categoryStatusCode ||
    (salesSummaryDetails?.length === 0 && !salesSummaryStatus) ||
    (analysisDetails?.length === 0 && !analysisStatus);

  const {
    retrieveCustomerData,
    retrieveCategories,
    retrieveSalesSummaryDetails,
    retrieveAnalysisDetails,
    retrievePaymentRange,
  } = useRetrieveFunctions();

  useEffect(() => {
    retrieveCustomerData();
    retrieveCategories();
    retrievePaymentRange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(AnalysisStatus(false));
      dispatch(SalesSummaryStatus(false));

      await retrieveSalesSummaryDetails(
        selectedCategory,
        selectedParty?.name,
        selectedRange,
      );
      await retrieveAnalysisDetails(
        selectedCategory,
        selectedParty?.name,
        selectedRange,
      );

      dispatch(AnalysisStatus(true));
      dispatch(SalesSummaryStatus(true));
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedParty, selectedRange]);

  const isCircularLoader =
    selectedCategory !== "" && !analysisStatus && !salesSummaryStatus;

  return (
    <>
      <Header />

      {isCircularLoader && <CircularLoader opacity={0.6} />}

      {isAnalysisLoader ? (
        <Loader />
      ) : (
        <AnalysisPage
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedParty={selectedParty}
          setSelectedParty={setSelectedParty}
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
        />
      )}

      <Footer />
    </>
  );
}

export default Analysis;
