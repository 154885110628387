import React, { useEffect } from "react";
import { Header } from "../Header/Header";
import { Loader } from "../Loader/Loader";
import { useSelector } from "react-redux";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { AccordionList } from "./Accordion/AccordionList";
import { Footer } from "../Footer/Footer";
import { MINUTE_MS } from "../Utils/common";
import "./PendingBags.css";

// ===================================================|| PENDING BAGS ||=================================================== //

function PendingBags({ isHomePage, selectedCategory, categoryList }) {
  const { itemWiseOrderedStatusCode, orderItemTransactionsDataStatusCode } =
    useSelector((state) => state.status_menu);

  const { retrieveItemWiseOrders, retrieveAllOrderItemTransactions } =
    useRetrieveFunctions();

  useEffect(() => {
    retrieveItemWiseOrders();
    retrieveAllOrderItemTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      retrieveItemWiseOrders();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />

      {!itemWiseOrderedStatusCode || !orderItemTransactionsDataStatusCode ? (
        <Loader />
      ) : (
        <AccordionList
          isHomePage={isHomePage}
          selectedCategory={selectedCategory}
          categoryList={categoryList}
        />
      )}

      <Footer />
    </>
  );
}

export default PendingBags;
