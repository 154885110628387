import React from "react";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { OrderChartDetails } from "./OrderDetailsChart";
import { DispatchChartDetails } from "./DispatchChartDetails";
import { PaymentChartDetails } from "./PaymentChartDetails";

// ==============================================|| ANALYSIS CHART ||============================================= //

export const AnalysisChartDetails = ({
  selectedCategory,
  selectedParty,
  selectedRange,
}) => {
  const { analysisDetails } = useSelector((state) => state.kpi_menu);

  return (
    <Stack direction="row" className="analysis-chart-stack">
      <OrderChartDetails
        orderedDetailsData={analysisDetails?.orderDetails}
        selectedCategory={selectedCategory}
      />

      <DispatchChartDetails
        dispatchedDetails={analysisDetails?.dispatchDetails}
        selectedCategory={selectedCategory}
      />

      <PaymentChartDetails
        paymentDetails={analysisDetails?.paymentDetails}
        selectedRange={selectedRange}
      />
    </Stack>
  );
};
