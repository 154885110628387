/* eslint-disable no-unreachable */
import dayjs from "dayjs";
import { uiTexts } from "./uiTexts";

// ===================================================|| COMMON ||=================================================== //

const {
  keyLabels: {
    userNameText,
    userTypeText,
    tabValueText,
    enGBText,
    enINText,
    numericText,
    longText,
  },
  labels: { bagLowerCaseText, stringText },
  placeHolderTexts: { dateAndTimeInputFormat },
  warnings: { errorText },
  separators: { colonText },
} = uiTexts;

export const getUserName = () => {
  const userName = sessionStorage.getItem(userNameText);
  if (userName) return userName;
  else return null;
};

export const setUserSession = (userName) => {
  sessionStorage.setItem(userNameText, userName);
};

export const getUserType = () => {
  const userType = sessionStorage.getItem(userTypeText);
  if (userType) return userType;
  else return null;
};

export const setUserType = (userType) => {
  sessionStorage.setItem(userTypeText, userType);
};

export const removeUserSession = () => {
  sessionStorage.removeItem(userNameText);
  sessionStorage.removeItem(userTypeText);
  sessionStorage.clear();
  localStorage.clear();
};

export const setTabValue = (tabValue) => {
  sessionStorage.setItem(tabValueText, tabValue);
};

// Helper function to format date
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(enGBText, {
    day: numericText,
    month: longText,
    year: numericText,
  });
};

// Helper function to format amount
export const formatAmount = (amount) => {
  return new Intl.NumberFormat(enINText, {
    maximumFractionDigits: 0,
  }).format(amount);
};

//Function to calculate netAmount
export const getNetAmount = (order, includeTax = false, isEditBillModal) => {
  let totalWeight;

  if (order.quantityInKg === null) {
    const unitMatch = order.unitListName.match(/(\d+)\s*[kK][gG]/);

    if (unitMatch === null) {
      throw new Error(`${errorText}${colonText} ${order.unitListName}`);
    }

    const unit = parseFloat(unitMatch[1]);

    totalWeight = isEditBillModal
      ? Number(order.bag) * unit
      : Number(order.pendingBag) * unit;
  } else {
    totalWeight = Number(order.quantityInKg);
  }

  const rate = Number(order.rate);
  const taxAmount = Number(order.taxAmount);

  let itemTotal = totalWeight * (rate / 100);

  if (includeTax) {
    itemTotal += taxAmount;
  }

  return itemTotal;
};

export const MINUTE_MS = 20000; // Interval duration (20 seconds)

//Function to calculate total of pending orders and pending amount
export const calculateTotal = (data, key) => {
  return data.reduce((total, item) => {
    return total + item[key];
  }, 0);
};

export const renderDateTime = (setDate, shouldUpdate) => {
  if (!shouldUpdate) {
    const interval = setInterval(() => {
      setDate(dayjs());
    }, 60000);

    return interval;
  }
  return undefined;
};

export const handleDateSelect = (value, setUpdate, setDate) => {
  setUpdate(true);
  const formattedDate = dayjs(value).format(dateAndTimeInputFormat);
  setDate(formattedDate);
};

export const handleUnknown = (unKnownText, value, defaultValue = "") => {
  return value !== unKnownText &&
    value !== null &&
    value !== undefined &&
    value !== ""
    ? value
    : defaultValue;
};

export const extractNumericValue = (unitListName) => {
  const match = unitListName.match(/(\d+)/);
  return match ? Number(match[1]) : 1;
};

// Function to capitalize the first letter and remaing are lower case
export function capitalizeFirstLowercaseRest(input) {
  if (typeof input !== stringText || input.length === 0) {
    return input;
  }

  return input
    .split(",")
    .map((part) => {
      const trimmedPart = part.trim();
      return (
        trimmedPart.charAt(0).toUpperCase() + trimmedPart.slice(1).toLowerCase()
      );
    })
    .join(", ");
}

//Check if the field is empty
export const isEmptyField = (str) => !str.trim();

// Function to calculate the total quantity
export const calculateTotalQuantity = (gatePassData, orderIndex) => {
  return gatePassData[orderIndex]?.gatePassDetails.reduce(
    (total, detail) => total + (parseInt(detail.quantity) || 0),
    0,
  );
};

// Render gate pass data
export const renderGatePassData = (dummyOrder) => {
  return dummyOrder?.map((order) => ({
    orderId: order.id,
    pendingBag: order.hasOwnProperty(bagLowerCaseText)
      ? order.bag
      : order.pendingBag,
    quantityInKg: order.quantityInKg,
    gatePassDetails: [
      {
        quantity: "",
        vehicleNumber: "",
        quantityError: "",
      },
    ],
  }));
};

// function to get the correct pending bag value
export const getPendingBagOrQuantity = (gatePass) => {
  return gatePass.pendingBag !== null
    ? gatePass.pendingBag
    : gatePass.quantityInKg;
};

// function to validate if input contains only alphabets and spaces
export const isValidAlphabeticalInput = (value) => {
  return /^[a-zA-Z\s]*$/.test(value);
};

export const renderEditGatePassData = (data) => {
  return data?.gatePassList?.map((order) => {
    const detailItem = data?.details.find(
      (detail) => detail.id === order.orderItemTransactionId,
    );

    return {
      orderItemTransactionId: detailItem?.id,
      pendingBag: detailItem?.bag,
      quantityInKg: detailItem?.quantityInKg,
      gatePassDetails: order.gatePassDetails?.map((detail) => ({
        id: detail.id,
        quantity: detail.bag,
        vehicleNumber: detail.vehicleNo,
        driverName: detail.driverName,
        quantityError: "",
      })),
    };
  });
};
