import React from "react";
import { Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { useSelector } from "react-redux";
import { EditBillLhsContent } from "../EditBillsLhsContent/EditBillsLhsContent";
import "../../CustomersTab/CustomerPage.css";
import "../../EditBills/editbills.css";

// ============================================|| EDIT BILLS CONTENT ||============================================ //

export const EditBillContent = ({
  searchList,
  setSelectedCustomer,
  selectedCustomer,
  filteredCustomersData,
}) => {
  const {
    warnings: { customerNotFoundText },
  } = uiTexts;

  const { pendingBillsData } = useSelector((state) => state.data_menu);

  const isEmptyData =
    pendingBillsData?.length !== 0 &&
    (filteredCustomersData?.length === 0 ||
      filteredCustomersData?.length === undefined);

  return (
    <div className="customer-page-style edit-container">
      {isEmptyData ? (
        <Typography className="order-customer-not-found">
          {customerNotFoundText}
        </Typography>
      ) : (
        <EditBillLhsContent
          searchList={searchList}
          setSelectedCustomer={setSelectedCustomer}
          selectedCustomer={selectedCustomer}
          filteredCustomersData={filteredCustomersData}
        />
      )}
    </div>
  );
};
