import React from "react";
import { TableBody, TableCell, TableRow, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../Utils/uiTexts";
import {
  formatDate,
  formatAmount,
  getNetAmount,
  extractNumericValue,
} from "../../../Utils/common";
import "../../OrderPage.css";

// ===================================================|| ORDER DETAILS TABLE ITEM ||=================================================== //

export const OrderSelectedDetailsTableItem = ({
  dummyOrder,
  setDummyOrder,
  filteredOrders,
  errorState,
  setErrorState,
  isEditBillModal,
  selectedBillData,
  setSelectedBillData,
  selectedBillBag,
}) => {
  const {
    warnings: { inValidValueText },
    labels: {
      unKnownText,
      pendingBagText,
      ratetext,
      taxAmountText,
      bagLowerCaseText,
      deliveryRateLowerCaseText,
    },
  } = uiTexts;

  const { salesParametersData } = useSelector((state) => state.data_menu);

  const handleInputChange = (index, field, value) => {
    const updatedOrder = [...dummyOrder];
    const updatedErrorState = [...errorState];

    // Check for valid numeric input
    if (!/^\d*\.?\d*$/.test(value) && value !== "") {
      updatedErrorState[index] = { ...updatedErrorState[index], [field]: true };
      setErrorState(updatedErrorState);
      return;
    }
    const newValue = value === "" ? 0 : Number(value);
    let error = false;

    // Store original values
    const originalRate = updatedOrder[index].rate;
    const originalDeliveryRate = updatedOrder[index].deliveryRate;

    if (field === pendingBagText) {
      const originalValue = Number(filteredOrders[index]?.pendingBag);
      const LimitBags =
        originalValue + Number(salesParametersData[0]?.limitValue);
      error = newValue > LimitBags || newValue <= 0;
      updatedOrder[index].pendingBag = newValue;
    } else if (field === ratetext) {
      error = newValue <= 0;
      updatedOrder[index].rate = newValue;
    } else if (field === deliveryRateLowerCaseText) {
      error = newValue >= originalRate;

      if (!error) {
        const deliveryRateDifference = newValue - originalDeliveryRate;
        updatedOrder[index].rate = originalRate - deliveryRateDifference;

        if (updatedOrder[index].rate < 0) {
          updatedOrder[index].rate = 0;
          error = true;
        }
      }
    } else if (field === taxAmountText) {
      error = newValue < 0;
    } else if (field === bagLowerCaseText) {
      const updatedSelectedBillData = { ...selectedBillData };
      const targetDetail = updatedSelectedBillData?.details?.find(
        (detail) => detail.id === updatedOrder[index].id,
      );
      if (targetDetail) {
        targetDetail.bag = newValue;
        setSelectedBillData(updatedSelectedBillData);
      }

      if (filteredOrders[index]?.pendingBag === 0) {
        const originalValue =
          Number(selectedBillBag) + Number(salesParametersData[0]?.limitValue);
        error = newValue > originalValue || newValue <= 0;
      } else {
        const originalValue = Number(
          filteredOrders[index]?.pendingBag +
            Number(selectedBillBag) +
            Number(salesParametersData[0]?.limitValue),
        );
        error = newValue > originalValue || newValue <= 0;
      }
    }

    updatedErrorState[index] = { ...updatedErrorState[index], [field]: error };

    if (!error) {
      updatedOrder[index][field] = newValue;
    }

    // Update the state
    setDummyOrder(updatedOrder);
    setErrorState(updatedErrorState);
  };

  const calculateQuantity = (order, isEditBillModal) => {
    if (order.quantityInKg !== null) return order.quantityInKg;

    const multiplier = extractNumericValue(order.unitListName);
    return isEditBillModal
      ? order.bag * multiplier
      : order.pendingBag * multiplier;
  };

  const renderTextField = (index, field, value, isError) => (
    <TextField
      value={Number(value)}
      size="small"
      onChange={(e) => handleInputChange(index, field, e.target.value)}
      error={isError}
      helperText={isError ? inValidValueText : ""}
    />
  );

  return (
    <TableBody>
      {dummyOrder?.map((order, index) => (
        <TableRow key={index}>
          <TableCell className="bill-modal-font">{index + 1}</TableCell>

          <TableCell className="bill-modal-font">
            {formatDate(order.orderDate)}
          </TableCell>

          <TableCell className="bill-modal-font">
            {`${order.itemName}
            ${order.unitListName !== unKnownText ? order.unitListName : ""}
            ${
              order.cleanCategoryName !== unKnownText
                ? order.cleanCategoryName
                : ""
            }`}
          </TableCell>

          <TableCell className="bill-modal-font">
            {order.quantityInKg === null
              ? renderTextField(
                  index,
                  isEditBillModal ? bagLowerCaseText : pendingBagText,
                  isEditBillModal ? order.bag : order.pendingBag,
                  isEditBillModal
                    ? errorState[index]?.bag
                    : errorState[index]?.pendingBag,
                )
              : order.quantityInKg}
          </TableCell>

          <TableCell className="bill-modal-font">
            {calculateQuantity(order, isEditBillModal)}
          </TableCell>

          <TableCell className="bill-modal-font">
            {renderTextField(
              index,
              ratetext,
              order.rate,
              errorState[index]?.rate,
            )}
          </TableCell>

          <TableCell className="bill-modal-font">
            {renderTextField(
              index,
              deliveryRateLowerCaseText,
              order.deliveryRate || 0,
              errorState[index]?.deliveryRate,
            )}
          </TableCell>

          <TableCell className="bill-modal-font">
            {renderTextField(
              index,
              taxAmountText,
              order.taxAmount || 0,
              errorState[index]?.taxAmount,
            )}
          </TableCell>

          <TableCell>
            {formatAmount(getNetAmount(order, true, isEditBillModal))}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
