import React from "react";
import { NavigateBackIcon } from "../../CommonComp/NavigateBackIcon";
import { SearchField } from "../../CommonComp/SearchField";
import { uiTexts } from "../../Utils/uiTexts";
import "../../CustomersTab/CustomerPage.css";
import "../../EditBills/editbills.css";

// ============================================|| EDIT BILLS HEADER ||============================================ //

export const EditBillHeader = ({
  searchList,
  setSearchList,
  setSelectedCustomer,
}) => {
  const {
    placeHolderTexts: { searchCustomerText },
  } = uiTexts;

  return (
    <>
      <div className="edit-bill-back-icon customer-page-style">
        <NavigateBackIcon />
      </div>

      <div className="customer-page-style edit-search-field">
        <SearchField
          searchList={searchList}
          setSearchList={setSearchList}
          searchLabel={searchCustomerText}
          setSelectedData={setSelectedCustomer}
        />
      </div>
    </>
  );
};
