import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http.common";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");

// ========================================|| KPI SERVICE ||======================================== //
const analysisReport = "AnalysisReport";
const kpiReport = "KPIReport";

const constructUrlWithParamsKpi = (baseUrl, startDate, endDate) => {
  const params = [];

  if (startDate) {
    params.push(`startDate=${startDate}`);
  }
  if (endDate) {
    params.push(`endDate=${endDate}`);
  }

  return params.length > 0 ? `${baseUrl}?${params.join("&")}` : baseUrl;
};

export const getOverAllSalesDelay = (startDate, endDate) => {
  const url = constructUrlWithParamsKpi(
    `/${kpiReport}/sales-delay`,
    startDate,
    endDate,
  );
  return apiService.get(url, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getOrderToBillDelay = (startDate, endDate) => {
  const url = constructUrlWithParamsKpi(
    `/${kpiReport}/orderToBill`,
    startDate,
    endDate,
  );
  return apiService.get(url, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getBillToDispatchDelay = (startDate, endDate) => {
  const url = constructUrlWithParamsKpi(
    `/${kpiReport}/billToDispatch`,
    startDate,
    endDate,
  );
  return apiService.get(url, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getBillToPaymentDelay = (startDate, endDate) => {
  const url = constructUrlWithParamsKpi(
    `/${kpiReport}/billToPayment`,
    startDate,
    endDate,
  );
  return apiService.get(url, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const constructUrlWithParamsAnalyis = (baseUrl, category, party, range) => {
  const params = [];

  if (category) params.push(`categoryName=${encodeURIComponent(category)}`);
  if (party) params.push(`partyName=${encodeURIComponent(party)}`);
  if (range) params.push(`range=${encodeURIComponent(range)}`);

  return params.length > 0 ? `${baseUrl}?${params.join("&")}` : baseUrl;
};

export const getOverAllAnaysisData = (category, party, range) => {
  const url = constructUrlWithParamsAnalyis(
    `/${analysisReport}/getAll`,
    category,
    party,
    range,
  );
  return apiService.get(url, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getSalesSummary = (category, party, range) => {
  const url = constructUrlWithParamsAnalyis(
    `/${analysisReport}/salesSummary`,
    category,
    party,
    range,
  );
  return apiService.get(url, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getRangesForPayment = () => {
  return apiService.get(`/${analysisReport}/ranges`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};
