import { useDispatch } from "react-redux";
import {
  CustomerList,
  ItemList,
  BrandList,
  CategoryList,
  OrderData,
  CustomerPendingOrders,
  FirmNames,
  OrderItemTransactionsData,
  CustomerPendingPayments,
  ItemWiseOrderedData,
  BillsByDateData,
  TransportData,
  DispatchReportData,
  SalesParametersData,
  BillsData,
  PendingBillsData,
} from "../Store/Reducers/data_menu";
import {
  CustomerStatusCode,
  CustomerPendingOrderStatusCode,
  OrderItemTransactionsDataStatusCode,
  CustomerPendingStatusCode,
  ItemWiseOrderedStatusCode,
  CategoryStatusCode,
  BillsByDateStatusCode,
} from "../Store/Reducers/status_menu";
import {
  OverAllSalesDelayDetails,
  OverAllSalesDelayStatus,
  OrderToBillDelayDetails,
  OrderToBillDelayStatus,
  BillToDispatchDelayDetails,
  BillToDispatchDelayStatus,
  BillToPaymentDelayDetails,
  BillToPaymentDelayStatus,
  SalesSummaryDetails,
  SalesSummaryStatus,
  AnalysisDetails,
  AnalysisStatus,
  PaymentRange,
} from "../Store/Reducers/kpi_menu";
import {
  getAllCustomers,
  getAllItems,
  getAllBrands,
  getAllCategories,
  getAllFirms,
  getBillsByDate,
  getAllTransportData,
  getAllDispatchReportData,
  getAllSalesParametersData,
} from "../Services/DataService";
import {
  getAllCustomerPendingOrders,
  getAllOrderItemTransactions,
  getAllItemWiseOrders,
  getAllBillsData,
  getAllPendingBillsData,
} from "../Services/OrderService";
import { getAllOrders } from "../Services/OrderService";
import { getAllCustomerPendingPayments } from "../Services/PaymentService";
import {
  getOverAllSalesDelay,
  getOrderToBillDelay,
  getBillToDispatchDelay,
  getBillToPaymentDelay,
  getSalesSummary,
  getOverAllAnaysisData,
  getRangesForPayment,
} from "../Services/KpiService";

// ============================================|| RETRIEVE FUNCTIONS ||============================================ //

export default function useRetrieveFunctions() {
  const dispatch = useDispatch();

  const retrieveCustomerData = async () => {
    await getAllCustomers().then(({ data, status }) => {
      dispatch(CustomerList(data));
      if (status === 200) {
        dispatch(CustomerStatusCode(true));
      }
    });
  };

  const retrieveItems = async () => {
    await getAllItems().then(({ data }) => {
      dispatch(ItemList(data));
    });
  };

  const retrieveBrands = async () => {
    await getAllBrands().then(({ data }) => {
      dispatch(BrandList(data));
    });
  };

  const retrieveCategories = async () => {
    await getAllCategories().then(({ data, status }) => {
      dispatch(CategoryList(data));
      if (status === 200) {
        dispatch(CategoryStatusCode(true));
      }
    });
  };

  const retrieveFirmNames = async () => {
    await getAllFirms().then(({ data }) => {
      dispatch(FirmNames(data));
    });
  };

  const retrieveTransportData = async () => {
    await getAllTransportData().then(({ data }) => {
      dispatch(TransportData(data));
    });
  };

  const retrieveItemWiseOrders = async () => {
    await getAllItemWiseOrders().then(({ data, status }) => {
      dispatch(ItemWiseOrderedData(data));
      if (status === 200) {
        dispatch(ItemWiseOrderedStatusCode(true));
      }
    });
  };

  // ============================================|| ORDER DETAILS ||============================================ //

  const retrieveOrders = async () => {
    await getAllOrders().then(({ data }) => {
      dispatch(OrderData(data));
    });
  };

  const retrieveCustomerPendingOrders = async () => {
    await getAllCustomerPendingOrders().then(({ data, status }) => {
      dispatch(CustomerPendingOrders(data));
      if (status === 200) {
        dispatch(CustomerPendingOrderStatusCode(true));
      }
    });
  };

  const retrieveAllOrderItemTransactions = async () => {
    await getAllOrderItemTransactions().then(({ data, status }) => {
      dispatch(OrderItemTransactionsData(data));
      if (status === 200) {
        dispatch(OrderItemTransactionsDataStatusCode(true));
      }
    });
  };

  const retrieveSalesParametersData = async () => {
    await getAllSalesParametersData().then(({ data }) => {
      dispatch(SalesParametersData(data));
    });
  };

  // ============================================|| PAYMENT DETAILS ||============================================ //

  const retrieveCustomerPendingPayments = async () => {
    await getAllCustomerPendingPayments().then(({ data, status }) => {
      dispatch(CustomerPendingPayments(data));
      if (status === 200) {
        dispatch(CustomerPendingStatusCode(true));
      }
    });
  };

  // ============================================|| BILL DETAILS ||============================================ //

  const retrieveBillsByDate = async (date) => {
    await getBillsByDate(date).then(({ data, status }) => {
      dispatch(BillsByDateData(data));
      if (status === 200) {
        dispatch(BillsByDateStatusCode(true));
      }
    });
  };

  const retrieveDispatchReportData = async () => {
    await getAllDispatchReportData().then(({ data }) => {
      dispatch(DispatchReportData(data));
    });
  };

  const retrieveAllBillsData = async () => {
    await getAllBillsData().then(({ data }) => {
      dispatch(BillsData(data));
    });
  };

  const retrieveAllPendingBillsData = async () => {
    await getAllPendingBillsData().then(({ data }) => {
      dispatch(PendingBillsData(data));
    });
  };

  // ============================================|| KPI ||============================================ //

  const retrieveOverAllDelayDetails = async (startDate, endDate) => {
    await getOverAllSalesDelay(startDate, endDate).then(({ data, status }) => {
      dispatch(OverAllSalesDelayDetails(data));
      if (status === 200) {
        dispatch(OverAllSalesDelayStatus(true));
      }
    });
  };

  const retrieveOrderToBillDelay = async (startDate, endDate) => {
    dispatch(OrderToBillDelayStatus(false));
    await getOrderToBillDelay(startDate, endDate).then(({ data, status }) => {
      dispatch(OrderToBillDelayDetails(data));
      if (status === 200) {
        dispatch(OrderToBillDelayStatus(true));
      }
    });
  };

  const retrieveBillToDispatchDelay = async (startDate, endDate) => {
    dispatch(BillToDispatchDelayStatus(false));
    await getBillToDispatchDelay(startDate, endDate).then(
      ({ data, status }) => {
        dispatch(BillToDispatchDelayDetails(data));
        if (status === 200) {
          dispatch(BillToDispatchDelayStatus(true));
        }
      },
    );
  };

  const retrieveBillToPaymentDelay = async (startDate, endDate) => {
    dispatch(BillToPaymentDelayStatus(false));
    await getBillToPaymentDelay(startDate, endDate).then(({ data, status }) => {
      dispatch(BillToPaymentDelayDetails(data));
      if (status === 200) {
        dispatch(BillToPaymentDelayStatus(true));
      }
    });
  };

  // ============================================|| ANALYSIS ||============================================ //

  const retrieveSalesSummaryDetails = async (category, party, timeSpan) => {
    await getSalesSummary(category, party, timeSpan).then(
      ({ data, status }) => {
        dispatch(SalesSummaryDetails(data));
        if (status === 200) {
          dispatch(SalesSummaryStatus(true));
        }
      },
    );
  };

  const retrieveAnalysisDetails = async (category, party, timeSpan) => {
    await getOverAllAnaysisData(category, party, timeSpan).then(
      ({ data, status }) => {
        dispatch(AnalysisDetails(data));
        if (status === 200) {
          dispatch(AnalysisStatus(true));
        }
      },
    );
  };

  const retrievePaymentRange = async () => {
    await getRangesForPayment().then(({ data }) => {
      dispatch(PaymentRange(data));
    });
  };

  return {
    retrieveCustomerData,
    retrieveItems,
    retrieveBrands,
    retrieveCategories,
    retrieveFirmNames,
    retrieveTransportData,
    retrieveItemWiseOrders,

    retrieveOrders,
    retrieveCustomerPendingOrders,
    retrieveAllOrderItemTransactions,
    retrieveSalesParametersData,

    retrieveCustomerPendingPayments,

    retrieveBillsByDate,
    retrieveDispatchReportData,
    retrieveAllBillsData,
    retrieveAllPendingBillsData,

    retrieveOverAllDelayDetails,
    retrieveOrderToBillDelay,
    retrieveBillToDispatchDelay,
    retrieveBillToPaymentDelay,

    retrieveSalesSummaryDetails,
    retrieveAnalysisDetails,
    retrievePaymentRange,
  };
}
