import React from "react";
import { Paper } from "@mui/material";
import { AnalysisHeader } from "./AnalysisHeader/AnalysisHeader";
import { AnalysisChartDetails } from "./AnalysisDetails/AnalysisChartDetails";
import { AnalysisFilters } from "./AnalyisFilters/AnalysisFilters";
import "./Analysis.css";

// ==============================================|| ANALYSIS ||============================================= //

export const AnalysisPage = ({
  selectedCategory,
  setSelectedCategory,
  selectedParty,
  setSelectedParty,
  selectedRange,
  setSelectedRange,
}) => {
  return (
    <>
      <AnalysisFilters
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedParty={selectedParty}
        setSelectedParty={setSelectedParty}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
      />

      <Paper className="analysis-background">
        <AnalysisHeader selectedCategory={selectedCategory} />

        <AnalysisChartDetails
          selectedCategory={selectedCategory}
          selectedParty={selectedParty}
          selectedRange={selectedRange}
        />
      </Paper>
    </>
  );
};
