import React, { useState, useEffect } from "react";
import { Header } from "../Header/Header";
import { Paper } from "@mui/material";
import { KpiHeader } from "./KpiHeader/KpiHeader";
import { Footer } from "../Footer/Footer";
import { KpiDetails } from "./KpiDetails/KpiDetails";
import { useSelector } from "react-redux";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { Loader } from "../Loader/Loader";
import "./Kpi.css";

// ==============================|| KPI ||============================== //

export default function Kpi() {
  const { overAllSalesDelayStatus } = useSelector((state) => state.kpi_menu);
  const { retrieveOverAllDelayDetails } = useRetrieveFunctions();

  const [selectedKpi, setSelectedKpi] = useState({ index: null, data: null });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    retrieveOverAllDelayDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />

      {!overAllSalesDelayStatus ? (
        <Loader />
      ) : (
        <Paper className="kpi-background">
          <KpiHeader
            selectedKpi={selectedKpi}
            setSelectedKpi={setSelectedKpi}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />

          <KpiDetails
            selectedKpi={selectedKpi}
            setSelectedKpi={setSelectedKpi}
            startDate={startDate}
            endDate={endDate}
          />
        </Paper>
      )}

      <Footer />
    </>
  );
}
