import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./Loader.css";

// ==============================|| CIRCULAR LOADER ||============================== //

export const CircularLoader = ({ opacity }) => {
  return (
    <Box
      className="circular-loader"
      style={{ backgroundColor: `rgba(255, 255, 255, ${opacity})` }}
    >
      <CircularProgress />
    </Box>
  );
};
