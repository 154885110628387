import React from "react";
import { Grid, Typography } from "@mui/material";
import { KpiLhs } from "./KpiLhs";
import { KpiRhs } from "./KpiRhs";
import { uiTexts } from "../../Utils/uiTexts";
import { CircularLoader } from "../../Loader/CircularLoader";
import { useSelector } from "react-redux";

// ==========================================|| KPI DETAILS ||=========================================== //

export const KpiDetails = ({
  selectedKpi,
  setSelectedKpi,
  startDate,
  endDate,
}) => {
  const {
    alertLabels: {
      selectTheStageText,
      noDataText,
      chooseBothTheStartAndEndDateOrNeitherText,
    },
    labels: { loadingText },
  } = uiTexts;

  const {
    orderToBillDelayStatus,
    billToDispatchDelayStatus,
    billToPaymentDelayStatus,
  } = useSelector((state) => state.kpi_menu);

  const {
    orderToBillDelayDetails,
    billToDispatchDelayDetails,
    billToPaymentDelayDetails,
  } = useSelector((state) => state.kpi_menu);

  const renderNoDataMessage = (label) => (
    <div className="kpi-average-box-style">
      <Typography className="select-stage-text-style">{label}</Typography>
    </div>
  );

  const renderLoaderAndSelectStage = (isLoader, label) => (
    <>
      {isLoader && <CircularLoader opacity={0.3} />}

      <div className="kpi-average-box-style">
        <Typography className="select-stage-text-style">{label}</Typography>
      </div>
    </>
  );

  const renderKpiDetails = () => {
    if (selectedKpi.index === null) {
      return renderLoaderAndSelectStage(false, selectTheStageText);
    }

    if (selectedKpi.index === 0 && !orderToBillDelayStatus) {
      return renderLoaderAndSelectStage(true, loadingText);
    }

    if (selectedKpi.index === 1 && !billToDispatchDelayStatus) {
      return renderLoaderAndSelectStage(true, loadingText);
    }

    if (selectedKpi.index === 2 && !billToPaymentDelayStatus) {
      return renderLoaderAndSelectStage(true, loadingText);
    }

    return null;
  };

  const renderLhsKpiDetails = () => {
    if (
      selectedKpi.index === 0 &&
      orderToBillDelayDetails?.categoryWise.length === 0
    ) {
      return renderNoDataMessage(noDataText);
    }

    if (
      selectedKpi.index === 2 &&
      billToPaymentDelayDetails?.categoryWise.length === 0
    ) {
      return renderNoDataMessage(noDataText);
    }

    return null;
  };

  const renderRhsKpiDetails = () => {
    if (
      selectedKpi.index === 0 &&
      orderToBillDelayDetails?.partyWise.length === 0
    ) {
      return renderNoDataMessage(noDataText);
    }

    if (
      selectedKpi.index === 1 &&
      billToDispatchDelayDetails?.transportWise.length === 0
    ) {
      return renderNoDataMessage(noDataText);
    }

    if (
      selectedKpi.index === 2 &&
      billToPaymentDelayDetails?.partyWise.length === 0
    ) {
      return renderNoDataMessage(noDataText);
    }

    return null;
  };

  const noStartOrEndDateDetails = () => {
    if (
      (startDate !== null && endDate === null) ||
      (startDate === null && endDate !== null)
    ) {
      return renderNoDataMessage(chooseBothTheStartAndEndDateOrNeitherText);
    }
  };

  return (
    <Grid className="kpi-flex-row kpi-details-grid">
      <div className="details-cards-style">
        {noStartOrEndDateDetails() ||
          renderKpiDetails() ||
          renderLhsKpiDetails() || <KpiLhs selectedKpi={selectedKpi} />}
      </div>

      <div className="details-cards-style">
        {noStartOrEndDateDetails() ||
          renderKpiDetails() ||
          renderRhsKpiDetails() || <KpiRhs selectedKpi={selectedKpi} />}
      </div>
    </Grid>
  );
};
