/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";
import { ReportFilters } from "../ReportPage Details/Filters Details/ReportFilters";
import { ReportTable } from "./ReportTableDetails/ReportTable";
import { reportDownload } from "../DownloadReport/ReportDownload";
import useRetrieveFunctions from "../../CustomHooks/useRetrieveFunctions";
import {
  SelectedCategoryName,
  SelectedFirmName,
  BillsByDateData,
  DispatchReportData,
} from "../../Store/Reducers/data_menu";
import { BillsByDateStatusCode } from "../../Store/Reducers/status_menu";
import { useSelector } from "react-redux";
import { EmptyReportData } from "./EmptyReportData";
import { Loader } from "../../Loader/Loader";
import dayjs from "dayjs";

// ============================================|| REPORT CONTENTS ||============================================ //

export const ReportContents = () => {
  const {
    placeHolderTexts: {
      chooseReportText,
      chooseFirmText,
      chooseCategoryText,
      chooseDateText,
      dateFormat,
    },
    separators: { leftParenthesisText, rightParenthesisText },
    warnings: { nobillsForSelectedDateText },
    labels: {
      sNoText,
      areaText,
      partyText,
      categoryText,
      unitText,
      typeText,
      brandText,
      quantityText,
      billNumberText,
      itemNameText,
      totalText,
      amountText,
      rsText,
      rateUpperText,
      transportNameText,
      gatePassNumberText,
      vehicleNumberText,
    },
  } = uiTexts;

  const dispatch = useDispatch();

  const { retrieveBillsByDate, retrieveDispatchReportData } =
    useRetrieveFunctions();
  const {
    billsByDateData,
    selectedReportData,
    selectedFirmName,
    selectedCategoryName,
    dispatchReportData,
  } = useSelector((state) => state.data_menu);
  const { billsByDateStatusCode } = useSelector((state) => state.status_menu);

  //Headers for both reports
  const report_one = [
    sNoText,
    areaText,
    partyText,
    itemNameText,
    unitText,
    typeText,
    brandText,
    quantityText,
    transportNameText,
  ];

  const report_two = [
    sNoText,
    areaText,
    partyText,
    categoryText,
    unitText,
    typeText,
    brandText,
    quantityText,
    `${rateUpperText} ${leftParenthesisText}${rsText}${rightParenthesisText}`,
    billNumberText,
    `${totalText} ${amountText} ${leftParenthesisText}${rsText}${rightParenthesisText}`,
  ];

  const dispatch_report = [
    sNoText,
    areaText,
    partyText,
    itemNameText,
    unitText,
    typeText,
    brandText,
    quantityText,
    gatePassNumberText,
    transportNameText,
    vehicleNumberText,
  ];

  const [reportType, setReportType] = useState("");
  const [reportDate, setReportDate] = useState(dayjs());
  const [selectedFirm, setSelectedFirm] = useState("");
  const [selectedCategory, setSelctedCategory] = useState("");
  const [isDownloadReport, setIsDownloadReport] = useState(false);
  const [reportHeader, setReportHeader] = useState("");

  useEffect(() => {
    if (reportType !== 3) {
      dispatch(BillsByDateData([]));
      dispatch(BillsByDateStatusCode(false));
      retrieveBillsByDate(reportDate?.format(dateFormat));
    }
  }, [reportDate]);

  useEffect(() => {
    setSelectedFirm("");
    setSelctedCategory("");

    if (reportType === 3) {
      dispatch(DispatchReportData([]));
      retrieveDispatchReportData();
    }
  }, [reportType]);

  const isClearDisabled =
    reportType === "" && selectedFirm === "" && selectedCategory === "";

  const isDownloadDisabled =
    isDownloadReport ||
    billsByDateData?.length === 0 ||
    selectedReportData?.length === 0;

  const handleClear = () => {
    setReportType("");
    setReportDate(dayjs());
    setSelectedFirm("");
    setSelctedCategory("");
  };

  const handleFieldChange = (field, value) => {
    switch (field) {
      case chooseReportText: {
        dispatch(SelectedCategoryName(""));
        dispatch(SelectedFirmName(""));
        setReportType(value);
        break;
      }
      case chooseDateText: {
        const formattedDate = dayjs(value);
        setReportDate(formattedDate);
        break;
      }
      case chooseFirmText:
        setSelectedFirm(value);
        break;
      case chooseCategoryText:
        setSelctedCategory(value);
        break;
      default:
        break;
    }
  };

  const handleDownload = () => {
    setIsDownloadReport(true);
  };

  useEffect(() => {
    let reportHeaderValue;

    switch (reportType) {
      case 1:
        reportHeaderValue = report_one;
        break;

      case 2:
        reportHeaderValue = report_two;
        break;

      default:
        reportHeaderValue = dispatch_report;
        break;
    }

    setReportHeader(reportHeaderValue);
  }, [reportType, reportDate]);

  useEffect(() => {
    isDownloadReport &&
      reportDownload(
        reportType,
        selectedReportData,
        reportHeader,
        setIsDownloadReport,
        selectedFirmName,
        selectedCategoryName,
        reportDate,
      );
  }, [isDownloadReport]);

  return (
    <>
      {(!billsByDateStatusCode ||
        (reportType === 3 && dispatchReportData?.length === 0)) && <Loader />}

      <ReportFilters
        reportType={reportType}
        setReportType={setReportType}
        reportDate={reportDate}
        selectedFirm={selectedFirm}
        selectedCategory={selectedCategory}
        handleFieldChange={handleFieldChange}
        isClearDisabled={isClearDisabled}
        isDownloadDisabled={isDownloadDisabled}
        handleClear={handleClear}
        handleDownload={handleDownload}
        setSelectedFirm={setSelectedFirm}
        setSelctedCategory={setSelctedCategory}
      />

      <Divider className="report-divider" />

      {reportType &&
        (billsByDateData?.length !== 0 || dispatchReportData?.length !== 0 ? (
          <ReportTable
            reportHeader={reportHeader}
            reportType={reportType}
            selectedFirm={selectedFirm}
            selectedCategory={selectedCategory}
            reportDate={reportDate}
          />
        ) : (
          billsByDateStatusCode && (
            <EmptyReportData warningLabel={nobillsForSelectedDateText} />
          )
        ))}
    </>
  );
};
