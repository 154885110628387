import React, { useState } from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  Tooltip,
  Stack,
  IconButton,
} from "@mui/material";
import {
  formatDate,
  formatAmount,
  handleUnknown,
  extractNumericValue,
} from "../../../Utils/common";
import { uiTexts } from "../../../Utils/uiTexts";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useSelector } from "react-redux";
import { ConfirmationModal } from "../../ConfirmationModal/ConfirmationModal";

// ===================================================|| CUSTOMER ORDER DETAILS ||=================================================== //

export const CustomerOrderListDetails = ({
  setSelectedCustomer,
  handleChange,
  customerDetailsSelect,
  orderItems,
  setOrderItems,
}) => {
  const {
    separators: { hyphenText },
    labels: {
      unKnownText,
      advancePaymentRequiredText,
      deliveryDateIsApplicableText,
      discountRateIsApplicableText,
      fullyCompletedUpperCaseText,
    },
  } = uiTexts;

  const { salesParametersData } = useSelector((state) => state.data_menu);

  const [isOpenRemoveConfirmationModal, setIsOpenRemoveConfirmationModal] =
    useState(false);
  const [selectTransactionId, setSelectTransactionId] = useState();

  // Helper function to render TableCell content
  const TableCellContent = ({ content, tooltip }) => (
    <TableCell className="orders-table-cell-style">
      {tooltip ? (
        <Tooltip
          title={
            <Stack spacing={0.5}>
              {tooltip?.map((msg, i) => (
                <Typography key={i} variant="body2">{`${
                  i + 1
                }. ${msg}`}</Typography>
              ))}
            </Stack>
          }
          placement="top"
        >
          <Stack direction="row" spacing={0.5}>
            <div className="has-missing-data-dot header-info-list-cursor" />
            <Typography className="commodity-details-text">
              {content}
            </Typography>
          </Stack>
        </Tooltip>
      ) : (
        <Typography className="commodity-details-text">{content}</Typography>
      )}
    </TableCell>
  );

  const openRemoveConfirmationModal = (transactionId) => {
    setIsOpenRemoveConfirmationModal(true);
    setSelectTransactionId(transactionId);
  };

  const handleCloseConfirmationModal = () => {
    setIsOpenRemoveConfirmationModal(false);
    setSelectTransactionId();
  };

  return (
    <>
      <TableBody>
        {orderItems?.map((details, index) => {
          if (
            details.pendingBag === 0 ||
            details.transactionType === fullyCompletedUpperCaseText
          )
            return null;

          const tooltipMessages = [];
          if (details.advancePaymentNeedApplicable) {
            tooltipMessages.push(advancePaymentRequiredText);
          }
          if (details.deliveryDateApplicable) {
            tooltipMessages.push(deliveryDateIsApplicableText);
          }
          if (details.discountRateApplicable) {
            tooltipMessages.push(discountRateIsApplicableText);
          }

          return (
            <TableRow key={details.id}>
              <TableCellContent
                content={index + 1}
                tooltip={tooltipMessages.length > 0 ? tooltipMessages : null}
              />

              <TableCellContent content={formatDate(details.orderDate)} />

              <TableCellContent
                content={`${details.itemName} ${handleUnknown(
                  unKnownText,
                  details.unitListName,
                )} ${handleUnknown(unKnownText, details.cleanCategoryName)}`}
              />

              <TableCellContent
                content={
                  details.quantityInKg === null
                    ? details.pendingBag
                    : hyphenText
                }
              />

              <TableCellContent
                content={
                  details.quantityInKg === null
                    ? details.pendingBag *
                      extractNumericValue(details.unitListName)
                    : details.quantityInKg
                }
              />

              <TableCellContent
                content={handleUnknown(
                  unKnownText,
                  details.brandName,
                  hyphenText,
                )}
              />
              <TableCellContent content={formatAmount(details.rate)} />

              <TableCellContent content={details.transactionType} />

              <TableCell className="orders-table-cell-style">
                <Checkbox
                  size="small"
                  checked={customerDetailsSelect.includes(details.id)}
                  onChange={() => handleChange(details.id)}
                />
              </TableCell>

              {details.quantityInKg === null &&
                details.pendingBag <= salesParametersData[1]?.limitValue && (
                  <TableCell className="orders-table-cell-style">
                    <IconButton
                      onClick={() => openRemoveConfirmationModal(details.id)}
                    >
                      <RemoveCircleOutlineIcon className="bill-modal-transport-remove-icon" />
                    </IconButton>
                  </TableCell>
                )}
            </TableRow>
          );
        })}
      </TableBody>

      {isOpenRemoveConfirmationModal && (
        <ConfirmationModal
          setIsOpenRemoveConfirmationModal={setIsOpenRemoveConfirmationModal}
          isOpenRemoveConfirmationModal={isOpenRemoveConfirmationModal}
          handleCloseConfirmationModal={handleCloseConfirmationModal}
          selectTransactionId={selectTransactionId}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
    </>
  );
};
