// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  overAllSalesDelayDetails: [],
  overAllSalesDelayStatus: false,
  orderToBillDelayDetails: [],
  orderToBillDelayStatus: false,
  billToDispatchDelayDetails: [],
  billToDispatchDelayStatus: false,
  billToPaymentDelayDetails: [],
  billToPaymentDelayStatus: false,

  salesSummaryDetails: [],
  salesSummaryStatus: false,
  AnalysisDetails: [],
  AnalysisStatus: false,
  PaymentRange: [],
};

// ==============================|| SLICE - KPI MENU ||============================== //

const kpi_menu = createSlice({
  name: "kpi_menu",
  initialState,
  reducers: {
    OverAllSalesDelayDetails(state, action) {
      state.overAllSalesDelayDetails = action.payload;
    },
    OverAllSalesDelayStatus(state, action) {
      state.overAllSalesDelayStatus = action.payload;
    },
    OrderToBillDelayDetails(state, action) {
      state.orderToBillDelayDetails = action.payload;
    },
    OrderToBillDelayStatus(state, action) {
      state.orderToBillDelayStatus = action.payload;
    },
    BillToDispatchDelayDetails(state, action) {
      state.billToDispatchDelayDetails = action.payload;
    },
    BillToDispatchDelayStatus(state, action) {
      state.billToDispatchDelayStatus = action.payload;
    },
    BillToPaymentDelayDetails(state, action) {
      state.billToPaymentDelayDetails = action.payload;
    },
    BillToPaymentDelayStatus(state, action) {
      state.billToPaymentDelayStatus = action.payload;
    },

    SalesSummaryDetails(state, action) {
      state.salesSummaryDetails = action.payload;
    },
    SalesSummaryStatus(state, action) {
      state.salesSummaryStatus = action.payload;
    },
    AnalysisDetails(state, action) {
      state.analysisDetails = action.payload;
    },
    AnalysisStatus(state, action) {
      state.analysisStatus = action.payload;
    },
    PaymentRange(state, action) {
      state.paymentRange = action.payload;
    },
  },
});

export default kpi_menu.reducer;

export const {
  OverAllSalesDelayDetails,
  OverAllSalesDelayStatus,
  OrderToBillDelayDetails,
  OrderToBillDelayStatus,
  BillToDispatchDelayDetails,
  BillToDispatchDelayStatus,
  BillToPaymentDelayDetails,
  BillToPaymentDelayStatus,

  SalesSummaryDetails,
  SalesSummaryStatus,
  AnalysisDetails,
  AnalysisStatus,
  PaymentRange,
} = kpi_menu.actions;
