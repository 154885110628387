/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import { uiTexts } from "../../Utils/uiTexts";

// ==============================================|| COMMODITY WISE ORDERS CHART ||============================================= //

export const CommodityWiseOrdersChart = ({ commodityOrderDetails }) => {
  const {
    chartLabels: { donutText, commodityWiseOrdersText },
    separators: { percentageText },
    alertLabels: { noDataText },
  } = uiTexts;

  const [chartData, setChartData] = useState({ options: {}, series: [] });

  useEffect(() => {
    if (!commodityOrderDetails || !Array.isArray(commodityOrderDetails)) {
      return;
    }

    const labels = commodityOrderDetails?.map((item) => item.categoryName);
    const series = commodityOrderDetails?.map((item) => item.ordersTaken);

    if (!labels || !series) {
      return;
    }

    const colorCodes = ["#03008D", "#02B2AF", "#2E96FF", "#B800D8"];
    const options = {
      chart: {
        type: donutText,
      },
      labels,
      colors: colorCodes,
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "var(--font-size)",
                color: "#373d3f",
                offsetY: -10,
              },
              value: {
                show: false,
              },
              total: {
                show: true,
                showAlways: true,
                label: commodityWiseOrdersText,
                fontSize: "11px",
                fontWeight: 600,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => `${val.toFixed(0)}${percentageText}`,
        dropShadow: {
          enabled: false,
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} ${percentageText}`,
        },
      },
    };

    setChartData({ options, series });
  }, [commodityOrderDetails]);

  return (
    <Paper className="sales-summary-background commoditywise-orders-style">
      <div className="donut-chart">
        {chartData?.series?.length === 0 ? (
          <div className="no-data-text">
            <Typography>{noDataText}</Typography>
          </div>
        ) : (
          <Chart
            options={chartData?.options}
            series={chartData?.series}
            type={donutText}
            width="280"
            height="200"
          />
        )}
      </div>
    </Paper>
  );
};
