import React, { useState, useEffect } from "react";
import { Dialog, Divider } from "@mui/material";
import { BillModalHeader } from "./BillModalHeader";
import { BillInputs } from "./BillInputs";
import {
  updateBill,
  getCustomerPendingPaymentsById,
} from "../../Services/PaymentService";
import { useSelector, useDispatch } from "react-redux";
import { getAllCustomerPendingPayments } from "../../Services/PaymentService";
import {
  CustomerPendingPayments,
  SelectedBillDetails,
} from "../../Store/Reducers/data_menu";
import { renderDateTime } from "../../Utils/common";
import dayjs from "dayjs";
import { BillDialogTitle } from "../../CommonComp/BillDialogTitle";
import { uiTexts } from "../../Utils/uiTexts";
import { getUserName, capitalizeFirstLowercaseRest } from "../../Utils/common";

// ===================================================|| BILL MODAL ||=================================================== //

export const BillSelectedModal = ({
  selectedPayment,
  isBillSelected,
  setIsBillSelected,
  orderItemTransactionIdData,
  setOrderItemTransactionIdData,
  selectedPaymentDetails,
  setSelectedPaymentDetails,
  filteredBillDetails,
  setFilteredBillDetails,
  setSelectedPayment,
  setIsOpenAlert,
}) => {
  const {
    deductionValues: { normalUpperCaseText },
  } = uiTexts;

  const dispatch = useDispatch();

  const { selectedBillDetails } = useSelector((state) => state.data_menu);

  const loginUserName = capitalizeFirstLowercaseRest(getUserName());

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [updateDate, setUpdateDate] = useState(false);
  const [amount, setAmount] = useState("");
  const [deductionType, setDeductionType] = useState(normalUpperCaseText);
  const [isPaymentDetailsModalOpen, setPaymentDetailsModalOpen] =
    useState(false);

  useEffect(() => {
    const intervalId = renderDateTime(setSelectedDate, updateDate);

    return () => {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
      }
    };
  }, [updateDate]);

  const handleUpdate = async () => {
    let data = {
      date: selectedDate,
      amount: amount,
      userName: loginUserName,
      type: deductionType,
    };

    const updateResult = await updateBill(selectedBillDetails.billNo, data);

    //Update pending bill details
    if (updateResult.status === 200) {
      const result = await getCustomerPendingPaymentsById(
        selectedPayment.partyId,
      );

      setSelectedPaymentDetails(result.data);

      //Update customer pending payments
      getAllCustomerPendingPayments().then(({ data, status }) => {
        if (status === 200) {
          dispatch(CustomerPendingPayments(data));

          // Filter data by the selected payment's partyId
          const updatedDetails = data.find(
            (res) => res.partyId === selectedPayment?.partyId,
          );

          setSelectedPayment(updatedDetails);

          // If updatedDetails exist, further filter to match the specific billNos
          if (updatedDetails) {
            const details = updatedDetails.billNos.filter(
              (bill) => bill.billNo === selectedBillDetails?.billNo,
            );

            dispatch(SelectedBillDetails(details));
          }
        }
      });

      // Update the selected bill details
      const filteredDetails = selectedPaymentDetails?.filter(
        (res) => res.billNo === selectedBillDetails.billNo,
      );

      setFilteredBillDetails(filteredDetails);
      setIsOpenAlert(true);
    }
    handleCancel();
  };

  const handleCancel = () => {
    setSelectedDate(dayjs());
    setAmount("");
    setDeductionType(normalUpperCaseText);
    setIsBillSelected(false);
    setUpdateDate(false);
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={isBillSelected}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "60rem",
          height: "44rem",
          borderRadius: "20px",
        },
      }}
    >
      <BillDialogTitle
        onClose={() => setIsBillSelected(false)}
        titleLabel={selectedBillDetails.billNo}
      />

      <Divider className="bill-modal-divider" />

      <div
        className="bill-modal-scrollable-content"
        onScroll={() => setPaymentDetailsModalOpen(false)}
      >
        <BillModalHeader
          orderItemTransactionIdData={orderItemTransactionIdData}
          filteredBillDetails={filteredBillDetails}
          isPaymentDetailsModalOpen={isPaymentDetailsModalOpen}
          setPaymentDetailsModalOpen={setPaymentDetailsModalOpen}
        />

        <Divider className="bill-details-divider" />

        <BillInputs
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          amount={amount}
          setAmount={setAmount}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
          setUpdateDate={setUpdateDate}
          setDeductionType={setDeductionType}
        />
      </div>
    </Dialog>
  );
};
