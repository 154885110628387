import React, { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Loader } from "../Loader/Loader";
import { uiTexts } from "../Utils/uiTexts";
import { Header } from "../Header/Header";
import { MINUTE_MS } from "../Utils/common";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { Footer } from "../Footer/Footer";
import { SearchField } from "../CommonComp/SearchField";
import { OrderPageDetails } from "./OrderPageDetails";
import "./OrderPage.css";

// ===================================================|| ORDERS PAGE ||=================================================== //

function OrderPage() {
  const {
    placeHolderTexts: { searchCustomerText },
    warnings: { customerNotFoundText },
  } = uiTexts;

  const { customerPendingOrderStatusCode, customerStatusCode } = useSelector(
    (state) => state.status_menu,
  );
  const { customerPendingOrders } = useSelector((state) => state.data_menu);
  const {
    retrieveCustomerPendingOrders,
    retrieveSalesParametersData,
    retrieveCustomerData,
  } = useRetrieveFunctions();

  const [searchList, setSearchList] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState();

  // Filtering Customers
  const filteredCustomers = customerPendingOrders?.filter((customer) =>
    customer.partyName.toLowerCase().includes(searchList.toLowerCase()),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      retrieveCustomerPendingOrders();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    retrieveSalesParametersData();
    retrieveCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmptyData =
    customerPendingOrders?.length !== 0 &&
    (filteredCustomers?.length === 0 ||
      filteredCustomers?.length === undefined);

  return (
    <>
      <Header />

      {!customerPendingOrderStatusCode || !customerStatusCode ? (
        <Loader />
      ) : (
        <Stack direction="column">
          <div className="customer-search-container-order">
            <SearchField
              searchList={searchList}
              setSearchList={setSearchList}
              searchLabel={searchCustomerText}
              setSelectedData={setSelectedCustomer}
            />
          </div>

          {isEmptyData ? (
            <Typography className="order-customer-not-found">
              {customerNotFoundText}
            </Typography>
          ) : (
            <OrderPageDetails
              filteredCustomers={filteredCustomers}
              setSelectedCustomer={setSelectedCustomer}
              selectedCustomer={selectedCustomer}
            />
          )}
        </Stack>
      )}

      <Footer />
    </>
  );
}
export default OrderPage;
