/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Dialog, Divider } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import {
  getNetAmount,
  renderEditGatePassData,
  renderGatePassData,
} from "../../Utils/common";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { editBill } from "../../Services/OrderService";
import { BillDialogTitle } from "../../CommonComp/BillDialogTitle";
import { EditBillingDetails } from "./EditBillingDetails";
import { EditTransportingDetails } from "./EditTransportingDetails";
import useRetrieveFunctions from "../../CustomHooks/useRetrieveFunctions";
import { getUserName, capitalizeFirstLowercaseRest } from "../../Utils/common";

// ===================================================|| EDIT BILL MODAL ||=================================================== //

export const EditBillModal = ({
  customerDetailsSelect,
  setCustomerDetailsSelect,
  selectedCustomer,
  setSelectedCustomer,
  billClick,
  setBillClick,
  orderItems,
  setOrderItems,
  partyGroups,
  setPartyGroups,
  selectedBill,
  selectedBillData,
  setSelectedBillData,
  selectedBillBag,
  setSelectedBillBag,
  setSelectedBillId,
  selectedBillId,
}) => {
  const {
    buttonText: { noText, yesText },
    placeHolderTexts: { dateAndTimeInputFormat },
  } = uiTexts;

  const { retrieveTransportData, retrieveAllBillsData } =
    useRetrieveFunctions();
  const { transportData } = useSelector((state) => state.data_menu);

  useEffect(() => {
    retrieveTransportData();
  }, []);

  const filteredOrders = (selectedBillData?.gatePassList || []).map(
    (gatePass) => {
      const detailItem = selectedBillData?.details?.find(
        (detail) => detail.id === gatePass.orderItemTransactionId,
      );

      return {
        orderItemTransactionId: detailItem?.id,
        bag: detailItem?.bag,
        pendingBag: detailItem?.pendingBag,
        quantityInKg: detailItem?.quantityInKg,
      };
    },
  );

  const loginUserName = capitalizeFirstLowercaseRest(getUserName());

  const [dummyOrder, setDummyOrder] = useState(selectedBillData?.details);
  const [billNumber, setBillNumber] = useState(selectedBillData?.billNo);
  const [selectedFirm, setSelectedFirm] = useState(selectedBillData?.firmId);
  const [userComments, setUserComments] = useState(selectedBillData?.remarks);
  const [billUpdateError, setBillUpdateError] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    dayjs(selectedBillData?.date),
  );
  const [updateClick, setUpdateClick] = useState(false);
  const [updateDate, setUpdateDate] = useState(false);
  const [isArrowIcon, setIsArrowIcon] = useState(false);
  const [customerResponsibility, setCustomerResponsibility] = useState(
    selectedBillData?.transportId === "" ||
      selectedBillData?.transportId === null
      ? yesText
      : noText,
  );
  const [selectedTransport, setSelectedTransport] = useState();
  const [modifiedParty, setModifiedParty] = useState(selectedBillData?.partyId);
  const [gatePassData, setGatePassData] = useState();
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if (customerResponsibility === noText) {
      const initialTransport = transportData?.find(
        (transport) => transport.id === selectedBillData?.transportId,
      );
      setSelectedTransport(initialTransport);
    } else if (customerResponsibility === yesText) {
      setSelectedTransport(null);
    }
  }, [transportData, selectedBillData, customerResponsibility]);

  useEffect(() => {
    selectedBillData?.gatePassList !== null
      ? setGatePassData(renderEditGatePassData(selectedBillData))
      : setGatePassData(renderGatePassData(dummyOrder));
  }, [selectedBillData, customerResponsibility]);

  const handleUpdate = async () => {
    try {
      const totalAmount = dummyOrder.reduce(
        (acc, order) => acc + getNetAmount(order, false, true),
        0,
      );
      const totalTaxAmount = dummyOrder.reduce(
        (acc, order) => acc + Number(order.taxAmount),
        0,
      );
      const totalNetAmount = dummyOrder.reduce(
        (acc, order) => acc + getNetAmount(order, true, true),
        0,
      );

      const updateData = {
        partyId: modifiedParty,
        billNo: billNumber,
        firmId: selectedFirm,
        date: !updateDate
          ? selectedDate.format(dateAndTimeInputFormat)
          : selectedDate,
        totalAmount: totalAmount,
        totalTaxAmount: totalTaxAmount,
        totalNetAmount: totalNetAmount,
        userName: loginUserName,
        remarks: userComments,
        orderItemTransactionIds: dummyOrder.map((data) => data.id),
        details: dummyOrder.map((order, index) => ({
          orderItemTransactionId: order.id,
          bag: order.quantityInKg === null ? Number(order.bag) : null,
          rate: Number(order.rate),
          amount: getNetAmount(order, false, true),
          taxAmount: Number(order.taxAmount),
          netAmount: getNetAmount(order, true, true),
          quantityInKg:
            order.quantityInKg === null ? null : Number(order.quantityInKg),
        })),
        transportId: selectedTransport?.id,
        gatePassList: gatePassData?.map((gatePass) => ({
          orderItemTransactionId: gatePass.orderItemTransactionId,
          gatePassDetails: gatePass?.gatePassDetails?.map((details, index) => ({
            id: ++index,
            bag: details.quantity,
            vehicleNo: details.vehicleNumber,
            driverName: "",
            dispatchedOrNot: false,
          })),
        })),
      };

      await editBill(updateData, selectedBillId);

      retrieveAllBillsData();
      handleCancel();
    } catch (error) {
      setBillUpdateError(error.response.data);
      console.error("Error during handleUpdate:", error);
    }
  };

  const handleCancel = () => {
    setBillNumber("");
    setSelectedFirm("");
    setUserComments("");
    setSelectedDate(null);
    setBillClick(false);
    setUpdateDate(false);
    setModifiedParty(null);
    setCustomerResponsibility(noText);
    setSelectedTransport();
    setIsArrowIcon(false);
    setPartyGroups({ data: [], status: null });
    setSelectedBillBag();
    setSelectedBillId();
  };

  const billingCommonProps = {
    isArrowIcon,
    setIsArrowIcon,
    billUpdateError,
    setBillUpdateError,
    updateClick,
    setUpdateClick,
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={billClick}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "75rem",
          borderRadius: "20px",
        },
      }}
    >
      <BillDialogTitle onClose={handleCancel} titleLabel={selectedBill} />

      <Divider className="bill-details-divider" />

      {!isArrowIcon ? (
        <EditBillingDetails
          billingInfo={{
            filteredOrders,
            dummyOrder,
            setDummyOrder,
            billNumber,
            setBillNumber,
            selectedFirm,
            setSelectedFirm,
            selectedBillData,
            setSelectedBillData,
            selectedBillBag,
          }}
          userInfo={{
            userComments,
            setUserComments,
          }}
          appState={{
            selectedDate,
            setSelectedDate,
            updateDate,
            setUpdateDate,
            modifiedParty,
            setModifiedParty,
          }}
          billingCommonProps={billingCommonProps}
          partyGroups={partyGroups}
          setPartyGroups={setPartyGroups}
        />
      ) : (
        <EditTransportingDetails
          billingCommonProps={billingCommonProps}
          transportInfo={{
            dummyOrder,
            handleUpdate,
            handleCancel,
            customerResponsibility,
            setCustomerResponsibility,
            selectedTransport,
            setSelectedTransport,
            setGatePassData,
            gatePassData,
            setExpandedRows,
            expandedRows,
          }}
        />
      )}
    </Dialog>
  );
};
