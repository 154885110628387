import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Loader } from "../Loader/Loader";
import { useSelector } from "react-redux";
import "../HomePage/Home.css";

// ===================================================|| Category Buttons ||=================================================== //

export const CategoryButtons = ({
  categoryList,
  selectedCategory,
  setSelectedCategory,
}) => {
  const {
    categoryStatusCode,
    itemWiseOrderedStatusCode,
    orderItemTransactionsDataStatusCode,
  } = useSelector((state) => state.status_menu);

  useEffect(() => {
    if (categoryList?.length > 0 && !selectedCategory) {
      setSelectedCategory(categoryList[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList, selectedCategory]);

  const handleButtonClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const getCategoryButtonStyles = (isCategorySelected) => ({
    backgroundColor: isCategorySelected ? "#009a2b" : "#D9D9D980",
    color: isCategorySelected ? "#FFFFFF" : "#808080",
    "&:hover": {
      backgroundColor: isCategorySelected ? "#009a2b" : "#D9D9D980",
      boxShadow: "none",
    },
  });

  return (
    <div className="home-page-categories">
      {!categoryStatusCode ||
      !itemWiseOrderedStatusCode ||
      !orderItemTransactionsDataStatusCode ? (
        <Loader />
      ) : (
        categoryList?.map((category) => (
          <Button
            onClick={() => handleButtonClick(category.id)}
            variant="contained"
            className="home-page-categories-button"
            sx={getCategoryButtonStyles(selectedCategory === category.id)}
          >
            {category.categoryName}
          </Button>
        ))
      )}
    </div>
  );
};
