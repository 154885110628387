import { Routes, Route } from "react-router-dom";
import { uiTexts } from "../Utils/uiTexts";
import Login from "../LoginPage/Login";
import CustomerPage from "../CustomersTab/CustomerPage";
import PendingBags from "../Pending Bags/PendingBags";
import Report from "../Report Page/Report";
import AccessControl from "../AccsessControl/AccessControl";
import WarningPage from "../Warning/WarningPage";

// ==============================|| ROUTING ||============================== //

const Routing = () => {
  const {
    warnings: { pageNotFoundText },
  } = uiTexts;

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/home" element={<AccessControl />} />
      <Route path="/orders" element={<AccessControl />} />
      <Route path="/payment" element={<AccessControl />} />
      <Route path="/customers" element={<CustomerPage />} />
      <Route path="/pending-bags" element={<PendingBags />} />
      <Route path="/report" element={<Report />} />
      <Route path="/editBills" element={<AccessControl />} />
      <Route path="/kpi" element={<AccessControl />} />
      <Route path="/analysis" element={<AccessControl />} />
      <Route
        path="*"
        element={<WarningPage warningLabel={pageNotFoundText} />}
      />
    </Routes>
  );
};

export default Routing;
