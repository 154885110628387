import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, ButtonGroup } from "@mui/material";
import { uiTexts } from "../Utils/uiTexts";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import {
  getUserName,
  getUserType,
  capitalizeFirstLowercaseRest,
} from "../Utils/common";

// ==============================|| HEADER TABS ||============================== //

export const HeaderTabs = () => {
  const {
    userTypesText: { salesTeamText, paymentTeamText, adminText },
    routingTexts: {
      orderLowerCaseText,
      paymentLowerCaseText,
      homeText,
      kpiLowerCaseText,
      analysisLowerCaseText,
    },
  } = uiTexts;

  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  const isSales =
    getUserType() === salesTeamText || getUserType() === adminText;
  const isPayment =
    getUserType() === paymentTeamText || getUserType() === adminText;
  const isAdmin = getUserType() === adminText;

  const {
    retrieveCustomerPendingOrders,
    retrieveFirmNames,
    retrieveCustomerPendingPayments,
  } = useRetrieveFunctions();

  useEffect(() => {
    preventBackFront();

    retrieveFirmNames();
    retrieveCustomerPendingOrders();
    retrieveCustomerPendingPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preventBackFront = () => {
    if (getUserName() !== null) {
      window.onpopstate = function () {
        navigate("/orders");
      };
    } else {
      navigate("/");
    }
  };

  // Set the active tab based on the current URL during the initial render
  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes(homeText)) {
      setActiveTab(homeText);
    } else if (currentPath.includes(orderLowerCaseText)) {
      setActiveTab(orderLowerCaseText);
    } else if (currentPath.includes(paymentLowerCaseText)) {
      setActiveTab(paymentLowerCaseText);
    } else if (currentPath.includes(kpiLowerCaseText)) {
      setActiveTab(kpiLowerCaseText);
    } else if (currentPath.includes(analysisLowerCaseText)) {
      setActiveTab(analysisLowerCaseText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname,
    homeText,
    orderLowerCaseText,
    paymentLowerCaseText,
    kpiLowerCaseText,
    analysisLowerCaseText,
  ]);

  // Check user access on path change
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === homeText) {
      navigate("/home");
    } else if (tab === orderLowerCaseText) {
      navigate("/orders");
    } else if (tab === paymentLowerCaseText) {
      navigate("/payment");
    } else if (tab === kpiLowerCaseText) {
      navigate("/kpi");
    } else if (tab === analysisLowerCaseText) {
      navigate("/analysis");
    }
  };

  const renderTabButton = (tabText) => (
    <Button
      variant={activeTab === tabText ? "contained" : "text"}
      className={
        activeTab === tabText
          ? "tab-button-style tab-button-selected"
          : "tab-button-style"
      }
      onClick={() => handleTabClick(tabText)}
    >
      {capitalizeFirstLowercaseRest(tabText)}
    </Button>
  );

  return (
    <ButtonGroup>
      {isAdmin && renderTabButton(homeText)}

      {isSales && renderTabButton(orderLowerCaseText)}

      {isPayment && renderTabButton(paymentLowerCaseText)}

      {isAdmin && renderTabButton(kpiLowerCaseText)}

      {isAdmin && renderTabButton(analysisLowerCaseText)}
    </ButtonGroup>
  );
};
